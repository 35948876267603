.Main-Head {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  border-bottom: 2px rgb(0, 179, 255) solid;
  flex-direction: row-reverse;
}

.Main-Head img {
  height: 60%;
  width: auto;
}

.Main-Head button {
  width: 100px;
  height: 40px;
  background: red;
  border: none;
  border-radius: 15px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.Main-Content {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row-reverse;
}

.Side-Bar {
  width: 250px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-left: 2px rgb(0, 179, 255) solid;
}

.Side-Bar button {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  border-bottom: 1px black solid;
  font-size: 20px;
  cursor: pointer;
  margin-top: 10px;
}

.activeButton {
  color: rgb(0, 179, 255);
}

.Main-Info {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
