/* General container for the login page */
.login-container {
  width: 100%;
  max-width: 400px;
  margin: 5rem auto;
  padding: 2rem;
  background: #f8f9fa; /* Light gray background */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-family: "Arial", sans-serif;
}

.login-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333; /* Darker text */
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #555;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.form-input:focus {
  border-color: #007bff; /* Focus color */
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
}

.input-error {
  border-color: red;
  background-color: #ffecec;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  text-align: center;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff; /* Primary blue */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.login-button:hover {
  background-color: #0056b3;
  transform: scale(1.02);
}

.login-button:disabled,
.loading-button {
  background-color: #aaa; /* Disabled/Loading state */
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .login-container {
    padding: 1.5rem;
    margin: 2rem auto;
  }

  .login-title {
    font-size: 1.5rem;
  }
}
