.SMain-Info {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
}

.Main-Info h1 {
  color: rgb(0, 179, 255);
}

.Content {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  padding-top: 50px;
  padding-bottom: 50px;
}

.Content div {
  width: 300px;
  height: 300px;
  border: 1px rgb(0, 179, 255) solid;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Content div h1 {
  font-size: 25px;
}
