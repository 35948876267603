.GHead {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.GHead h1 {
  color: rgb(0, 179, 255);
}

.Top-Bar {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
