.School-Main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.School-Main .Header {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 150px;
  border-bottom: 2px rgb(0, 179, 255) solid;
  flex-direction: row-reverse;
  gap: 50px;
}

.School-Main .Header h1 {
  color: rgb(0, 179, 255);
}

.School-Main .Header button {
  width: 150px;
  height: 40px;
  background: rgb(0, 179, 255);
  border: none;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.modal-content input {
  text-align: end;
}

.All-Schools {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: 50px;
}

.All-Schools .SInfo {
  width: 250px;
  height: fit-content;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border: 1px rgb(0, 179, 255) solid;
  gap: 5px;
  border-radius: 15px;
}

.All-Schools .SInfo p {
  height: 10px;
  color: rgb(0, 179, 255);
  font-size: 20px;
}

.All-Schools .SInfo .link {
  width: 100px;
  height: 40px;
  background: rgb(0, 179, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
  border-radius: 15px;
  font-size: 20px;
  margin-top: 20px;
}
